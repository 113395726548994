.hdv-barsuper{
    background-color: #fff;
    height: 50px;
    text-align: center;
    margin-right: 0px;
    margin-left: 0px;
    box-shadow: 0px 0px 5px 1px rgb(56, 56, 56);
}

.hdv-barsuper-button{
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    margin-top: 5px;
    cursor: pointer;
}

.hdv-barsuper-show{
    background-color: #1b73c5f5;
    margin-left: 5px;
}

.hdv-barsuper-show:hover{
    background-color: #3790e2d6;
}

.hdv-barsuper-back{
    background-color: #fff;
    border: 1px solid #444;
    color: #444;
    width: 80%;
}

.hdv-barsuper-slide {
    background-color: #f3ac29;
}

.hdv-barsuper-slide:hover {
    background-color: #ffbe44;
}

.hdv-barsuper-back:hover{
    background-color: #f2f2f2;
}

.hdv-barsuper-delete{
    background-color: tomato;
    width: 80%;
}

.hdv-barsuper-delete:hover{
    background-color: rgb(251, 130, 109);
}

.hdv-barsuper-new{
    background-color: #1bc57ef5;
    margin-right: 5px;
}

.hdv-barsuper-new:hover{
    background-color: #49da9df5;
}

.hdv-barsuper-update{
    background-color: #1b5cc5f5;
    margin-left: 5px;
}

.hdv-barsuper-update:hover{
    background-color: #3d71c5f5;
}

.hdv-barsuper-select{
    padding: 10px;
    border-radius: 5px;
    max-width: 300px;
    width: 100%;
    margin-top: 5px;
    background-color: #f2f2f2;
    border: none;
}


.hdv-barsuper-input{
    padding: 10px;
    border-radius: 5px;
    max-width: 300px;
    width: 100%;
    margin-top: 5px;
    background-color: #f2f2f2;
    border: none;
}

.hdv-barsuper-label{
    display: block;
    margin-bottom: 0px;
}

.hdv-barsuper-label-margin{
    margin-top: 20px;
}

.hdv-barsuper-bottom{
    padding-bottom: 6px;
    text-align: center;
}

.hdv-widget-config-fieldset{
    padding: 10px;
    margin: 0px;
    border: 1px solid silver;
    margin-top: 10px;
}

.hdv-widget-config-fieldset-legend{
    font-size: 14px;
    width: auto;
}

body {
    /*background-color: #c3c3c3 !important;*/
}

.set-background-color {
    background-color: #c3c3c3 !important;
}

.layout-inlineflex {
    display: inline-flex;
    margin-top: 10px;
    text-align: center;
}

.icon-big {
    font-size: 65px;
}

.value-super{
    font-size: 40px;
}

.black-txt{
    color: #000;
}

.green-txt{
    color: #1bc57ef5;
}

.red-txt{
    color: tomato;
}

.blue-txt{
    color: #1b73c5f5;
}

.yellow-txt{
    color: #ffb100f5;
}

.bar-info{
    background-color: darkgrey;
    bottom: 0px;
    position: absolute;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.bar-label {
    margin: 0px;
    position: relative;
    display: inline-grid;
    font-size: 11px;
    color: #fff;
    padding: 4px 0px 2px 0px;
    width: 33%;
}

.bar-label-green{
    background-color: #1bc57ef5;
    border-bottom-left-radius: 5px;
}

.bar-label-yellow{
    background-color: #ffb100f5;
}

.bar-label-red{
    width: 34%;
    background-color: tomato;
    border-bottom-right-radius: 5px;
}

.bar-value{
    text-align: center;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -132px;
    width: 260px;
    position: fixed;
}

.box-simple-value {
    height: 100%;
    position: relative;
    top: -41px;
}

.box-gauge{
    height: 100%;
    position: relative;
    top: -41px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.box-gauge-margintop{
    margin-top: 41px;
}

.hdv-bullet-well{
    height: 100%;
    position: relative;
    top: -41px;
}

.hdv-bullet-well-box{
    height: 100%;
    padding: 51px 10px 10px 10px;
}

.hdv-bullet-well-terrain{
    height: 100%;
    display: inline-flex;
    width: 70%;
}

.hdv-bullet-well-draw{
    width: 100%;
    height: 100%;
    /*background-color: #dea077;*/
    background-image: repeating-linear-gradient(#6dc16d 0%, #956b4f 5%, #dea077 100%);
    align-content: center;
    justify-content: center;
    display: flex;
    border-top: 26px solid #6dc16d;
}

.hdv-bullet-well-grade{
    width: 5%;
    height: 100%;
    display: inline-flex;
    margin-left: 5px;
    border-bottom: 1px dotted silver;
    border-right: 1px dotted silver;
    border-top: 1px dotted silver;
}

.hdv-bullet-well-grade-item{
    display: block;
    position: absolute;
    font-size: 9px;
    margin-left: 10px;
    color: silver;
}

.hdv-bullet-well-water{
    /*background-color: #88d0e8;*/
    background-image: repeating-linear-gradient(#88d0e8 0%, #78b8ce 5%, #70a9bd 100%);
    width: 45px;
    height: 488px;
    position: absolute;
    margin: 0 auto;
    bottom: 0;
    margin-bottom: 10px;
}

.hdv-bullet-well-level{
    /*background-color: #24b5e4;*/
    background-image: repeating-linear-gradient(#24b5e4 0%, #209cc5 5%, #2295bb 100%);
    width: 45px;
    height: 418px;
    position: absolute;
    margin: 0 auto;
    bottom: 0;
}

.hdv-water-pump-point{
    height: 24px;
    width: 56px;
    background-color: #616161;
    position: absolute;
    transform: translate(5px, 0px);
}

.hdv-water-pump-desc{
    font-size: 10px;
    color: white;
    margin-left: 1px;
}

.hdv-water-pump-desc-levels{
    top: -14px;
    position: absolute;
}

.hdv-water-pump-fan{
    color: tomato;
    font-size: 20px;
    position: absolute;
    top: 2px;
    left: 34px;
}

.hdv-image-widget{
    height: 100%;
    background-size: cover;
    background-position-y: 41px;
    background-repeat: no-repeat;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.hdv-bullet-well-static-point{
    width: 83px;
    height: 2px;
    position: absolute;
    background: #2461d0;
}

.hdv-bullet-well-dynamic-point{
    width: 83px;
    height: 2px;
    position: absolute;
    background: #5a7cb9;
}

.hdv-close-widget{
    font-size: 20px;
    color: red;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99999
}

.hdv-overlay{
    position: fixed;
    background-color: black;
    width: 100%;
    height: 100%;
    z-index: 99999;
    top: 0;
    opacity: 0.7;
}

.hdv-icons-list{
    position: fixed;
    background: black;
    top: 30%;
    margin: 0 auto;
    z-index: 9999999;
    text-align: center;
    align-items: center;
    align-content: center;
    padding: 20px 5px 5px 5px;
    width: 100%;
}

.hdv-icons-list h2 {
    color: #fff;
    font-weight: bold;
}

.hdv-icons-list ul {
    margin: 30px;
    padding: 0px;
}

.hdv-icons-list ul li {
    padding: 10px;
    display: inline-block;
}

.hdv-icons-list i {
    color: #fff;
    font-size: 30px;
}

.hdv-icons-list i:hover {
    color: #ffff00;
    cursor: pointer;
}

.hdv-metric-text{
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    top: -16px;
    right: -50px;
}

.hdv-water-pump-fan-none {
    color: #ffffff;
}

.hdv-vazao-detalhe {
    position: relative;
    top: 5px;
    width: 85px;
}

.hdv-water-pump-fan-on {
    color: #6dc16d;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
