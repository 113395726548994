:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

button:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

body {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 0.9rem;
}

.hdv-menubar {
  background-color: transparent !important;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.hdv-background-header {
  width: 100%;
  height: 200px;
  background: #2f577b;
  /* Old browsers */
  background: -moz-linear-gradient(top, #61abe8 0%, #2f577b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #61abe8 0%, #2f577b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #61abe8 0%, #2f577b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#258dc8', endColorstr='#258dc8', GradientType=0);
  /* IE6-9 */
}

.hdv-app-container {
  z-index: 10;
  position: absolute;
  top: 0px;
  width: 100%;
}

.hdv-body-app {
  width: 100%;
  margin-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.fixed {
  float: left;
  width: 250px;
  background-color: #37424A;
  display: block;
  position: fixed;
  height: 100%;
}

.fixed-toggle {
  display: none;
}

.fluid {
  margin-left: 250px;
  height: 100%;
}

.fluid-toggle {
  margin-left: 0px !important;
}

.hdv-logomarca {
  height: 200px;
  background-color: #37424a;
  text-align: center;
}

.hdv-bloco-informacao {
  border-radius: 4px;
  /*box-shadow: 1px 2px 8px -1px rgba(0,0,0,0.19);*/
}

.hdv-bloco-informacao-page-no-rgrid {
  background-color: #f2f2f2;
  padding: 10px 20px 10px 20px;
}

.hdv-bloco-top-space {
  margin-top: 20px;
}

.hdv-menu-grupo {
  font-size: 11px;
  margin: 20px 0px 2px 5px;
  font-weight: 600;
  color: #aaa;
}

.hdv-menu {
  font-size: 0.9rem;
  padding: 5px 4px 5px 10px;
  color: #c7c7c7;
}

.hdv-menu-parent {
  color: #fff;
}

.hdv-menu-parent:hover {
  text-decoration: none;
  color: #424242;
}

.hdv-menu-item {
  margin: 5px 0px 5px 25px;
  color: #aaa
}

.fa-20px {
  font-size: 20px !important;
}

.fa-30px {
  font-size: 30px !important;
}

.hdv-menu-image-top {
  line-height: 45px;
  margin-right: 15px;
}

.hdv-user-image {
  width: 40px;
}

.hdv-image-round {
  background-color: white;
  border-radius: 40px;
  display: block;
  border: 4px solid;
}

.hdv-logomarca img {
  width: 250px;
}

.hdv-menu-selected {
  background-color: #00000029;
  border-left: 5px solid #ffffff;
}

.hdv-menu:hover {
  background-color: #00000029;
  border-left: 5px solid #ffffff;
}

.fa-12px {
  font-size: 12px;
}

.hdv-menu-item-grupo {
  display: none;
}

.hdv-menu-item-grupo-open {
  display: block;
}

.hdv-caret-menu {
  float: right;
  margin-top: 5px;
  margin-right: 10px;
}

.hdv-info-bloco {
  padding: 10px;
  border-radius: 5px;
}

.hdv-title-bloco {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 300;
}

.hdv-body-bloco {
  font-size: 45px;
  color: #fff;
  font-weight: 300;
  text-align: right;
}

.hdv-info-bloco-active {
  background-color: #6fdaa2;
}

.hdv-info-bloco-alert {
  background-color: #dabd6f;
}

.hdv-info-bloco-off {
  background-color: #424242;
}

.hdv-info-bloco-sem-comunicacao {
  background-color: #da8b6f;
}

.rbc-calendar {
  height: 90% !important;
  padding: 10px;
}

.hdv-bloco-height-fix {
  height: 500px !important;
}

.weathercolor {
  background-color: #1f567c !important;
}

.hdv-widget01 {
  padding: 9px;
  background-color: #f2f2f2
}

.hdv-widget01 img {
  width: 100%;
  height: 90px;
}

.hdv-alert-receive {
  position: relative;
  z-index: 10;
  left: -25px;
  top: -7px;
  background-color: red;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 11px;
  padding: 3px 5px 3px 5px;
}

.hdv-alert-receive-hidden {
  visibility: hidden;
}

.hdv-menu-item:hover {
  margin: 5px 0px 5px 30px;
  color: #aaa
}

.hdv-submenu {
  color: #424242;
}

.hdv-submenu:hover {
  text-decoration: none;
  color: #424242;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.hdv-chart {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 8px;
  margin-top: 5px;
}

.hdv-chart-bloco {
  height: 500px;
  background-color: #fff;
}

.hdv-chart-bloco-reactgridlayout {
  height: 100%;
  background-color: #fbfbfb;
}

.hdv-chart-refs {
  margin-top: 10px;
  background-color: #f6f6f6;
  margin-bottom: 14px;
  border-radius: 5px;
}

.hdv-chart-refs summary {
  border-radius: 5px;
  background-color: #f6f6f6;
  padding: 10px 4px;
}

.hdv-chart-refs summary:hover {
  filter: brightness(0.94);
}

.hdv-chart-refs section {
  max-height: 200px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

.hdv-ref-item {
  margin: 0 14px;
  padding: 4px 8px;
  min-width: 20%;
  max-width: 20%;
  border-right: 2px solid #ccc;
}

.hdv-ref-item:last-child {
  border-right: none;
}

.hdv-ref-item #ref-line-color {
  width: 16px;
  height: 16px;
  margin: 0 4px;
  border-radius: 20px;
}

.hdv-top-bar-bloco {
  background-color: #efefef;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 0.9rem;
  color: #000;
  padding: 10px;
}

.hdv-zera-padding-left-right-boostrap {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 10px;
}

.hdv-render-div {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  background-color: white;
  border-radius: 4px;
  padding: 10px 20px 16px 20px;
  -webkit-box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.75);
}

.hdv-search-home-field {
  line-height: 39px;
  width: 300px;
  border: 1px solid #e6e6e6;
  border-radius: 30px;
  padding-left: 15px;
  outline: none;
  font-size: 0.9rem;
  color: #424242
}

.hdv-search-home-field::placeholder {
  font-size: 0.9rem
}

.nav-item, .nav-link {
  color: #fff !important;
}

.hdv-btn-forms {
  border: none;
  padding: 7px 30px 7px 30px;
  color: #fff;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  font-size: 0.9rem;
  cursor: pointer;
}

.hdv-btn-forms:hover {
  opacity: 0.8;
}

.hdv-btn-forms:focus {
  outline: none;
}

.btn-add-margin {
  margin-left: 10px;
}

.hdv-btn-yellow {
  background-color: #cac528;
}

.hdv-btn-geen {
  background-color: #56c17c;
}

.hdv-btn-geenlight {
  background-color: #79da9b;
}

.hdv-btn-red {
  background-color: #c15656;
}

.hdv-btn-blue {
  background-color: #0062cc;
}

.hdv-btn-orange {
  background-color: orange;
}

.hdv-btn-group {
  margin-top: 20px;
  margin-bottom: 12px;
}

.hdv-btn-disable {
  background-color: #cacaca !important;
}

.hdv-aligncenter-checkboxgrid {
  text-align: center;
}

.hdv-form-default {
  font-size: 0.9rem;
}

.hdv-form-default label {
  display: block;
  font-size: 13px;
  margin-top: 15px;
}

.hdv-form-default .required {
  font-weight: 600;
}

.hdv-form-default input[type="text"], input[type="number"], input[type="time"], input[type="date"], select {
  height: 42px;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  outline: none;
  width: 100%;
}

input[type="time"] {
  width: 100px;
}

.hdv-form-default input[type="text"], input[type="number"]:focus, .hdv-textarea {
  outline: none;
}

.hdv-form-system-password-field {
  height: 42px !important;
  padding: 7px !important;
  border-radius: 5px;
  border: 1px solid #d0d0d0 !important;
  outline: none !important;
  width: 100% !important;
  background-color: #fff !important;
}

.hdv-form-default input[type="radio"] {
  margin-left: 10px;
}

.hdv-form-default select {
  width: 100%;
  border: none;
  padding: 7px;
  background-color: #efefef;
  border-radius: 5px;
}

.hdv-textarea {
  min-height: 320px;
  max-height: 400px;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  outline: none;
  width: 100%;
}

.hdv-subtable-title {
  margin-top: 20px;
  margin-bottom: 10px;
}

.hdv-subtable {
  width: 100%;
  border: 1px solid #ebeced;
  font-size: 0.9rem;
}

.hdv-subtable thead {
  background-color: #f8f9fa;
  text-align: center;
}

.hdv-subtable thead td {
  border: 1px solid #ebeced;
  padding-left: 3px;
  padding: 10px;
}

.hdv-subtable tbody td {
  border: 1px solid #ebeced;
  padding: 5px;
}

.hdv-subtable-action-td {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.hdv-subtable-action-td button{
  width: 110px;
}

.hdv-noshow-item {
  display: none !important;
}

button:disabled, button[disabled] {
  background-color: #cacaca !important;
}

.hdv-form-error {
  border: 2px solid red !important;
}

.display-none {
  display: none;
}

.hdv-required-field {
  color: #ff0000b8;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  top: -3px;
  left: 5px;
}

.scroll-parent {
  height: 100%;
  padding-top: 220px;
  position: relative;
  z-index: 20;
  top: -220px;
}

#scroll-menu {
  overflow: auto;
  position: relative;
  height: 100%;
}

#scroll-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #37424a00;
}

#scroll-menu::-webkit-scrollbar {
  width: 5px;
  background-color: #37424a00;
}

#scroll-menu::-webkit-scrollbar-thumb {
  background-color: #9c9c9c;
  border: 2px solid #7d7d7dbf;
}

.hdv-header-filters {
  padding: 20px 0px;
  border-radius: 5px;
}

.hdv-header-filters label {
  display: block;
  font-size: 13px;
  font-weight: 600;
}

.hdv-header-filters select {
  width: 100%;
  padding: 7px;
  border-radius: 5px;
  border: 2px solid #d0d0d0;
  height: 42px;
  outline: none;
}

.hdv-header-filters input[type="text"] {
  padding: 7px;
  border-radius: 5px;
  border: 2px solid #d0d0d0;
  outline: none;
}

.hdv-header-filters-button {
  border: 2px solid #007bff;
  background-color: #fff;
  color: #77aeee;
  padding: 7px;
  border-radius: 5px;
  margin-left: 4%;
  width: 20%;
  outline: none;
  height: 42px
}

.hdv-generate-report {
  float: right;
  background-color: #007bff !important;
  color: #fff !important;
  margin-top: 15px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  cursor: pointer;
}

.hdv-generate-pdf {
  float: left;
  background-color: skyblue !important;
  color: #fff !important;
  margin-top: 15px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  cursor: pointer;
  width: 125px;
}

.hdv-block-pdf-border {
  border: 2px solid skyblue !important;
  background-color: skyblue !important;
}

.hdv-block-button {
  border: 2px solid #acacac !important;
  background-color: #acacac !important;
}

.hdv-report-loading {
  position: relative;
  float: right;
  top: 10px;
  right: 10px;
}

.hdv-report-loading-left {
  position: relative;
  float: left;
  top: 10px;
  left: 10px;
}

.hdv-title-create-profile {
  font-size: 15px;
  margin-top: 10px;
  background-color: #d0d0d0;
  color: #444;
  padding: 5px 6px 5px 6px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.create-profile-heading {
  color: #444;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #d0d0d0;
}

.create-profile-heading span {
  font-size: 15px !important;
}

.create-profile-heading summary {
  padding: 3px 10px !important;
  background-color: #d0d0d0 !important;
}

.create-profile-heading section {
  padding: 14px 8px;
}

.create-profile-heading button {
  padding: 6px 8px !important;
}

.hdv-permission-block {
  padding: 5px;
  border-left: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
}

.hdv-permission-description {
  margin-left: 12px;
  font-size: 12px;
}

.hdv-block-group {
  border-bottom: 1px solid #d0d0d0;
}

.hdv-open-close-block-group {
  float: right;
  color: #000;
  font-size: 16px;
  cursor: pointer;
}

.hdv-dias-semana-automacao {
  display: none;
}

/*
.css-10nd86i{
  margin-right: 5px;
  height: 42px;
}

.css-vj8t7z{
  border-width: 2px !important;
  border-color: #d0d0d0 !important;
  box-shadow: none !important;
  height: 42px !important;
}

.css-2o5izw{
  border-color: #d0d0d0 !important;
  border-width: 2px !important;
  box-shadow: none !important;
  height: 42px !important;
}

.css-2o5izw:hover{
  border-color: #d0d0d0 !important;
  border-width: 2px !important;
  box-shadow: none !important;
}

.css-2o5izw:active{
  border-width: 2px !important;
}

.css-10nd86i{
  font-size: 13px;
}
*/

.css-10nd86i {
  font-size: 12px;
  height: 40px;
}

.css-vj8t7z .css-1hwfws3 {
  height: 40px;
}

.css-2o5izw .css-1hwfws3 {
  height: 40px !important;
}

.css-xp4uvy {
  top: 66% !important;
}

.css-1492t68 {
  display: none;
}

.css-162g8z5 {
  font-size: 12px;
  height: 40px;
}

.css-1wy0on6 {
  height: 40px;
}

.hdv-datepicker {
  position: absolute;
  background-color: #efefef;
  padding: 10px;
  width: 578px;
  z-index: 5;
  border-radius: 5px;
}

.hdv-datepicker-display-none {
  display: none;
}

button, input, optgroup, select, textarea {
  font-size: 12px !important;
}

.hdv-force-hight {
  height: 42px !important;
}

.hdv-top15 {
  margin-top: 15px;
}

.hdv-resumo-titulo {
  font-size: 13px;
}

.hdv-resumo-valor {
  font-size: 13px;
  font-weight: bold;
}

.hdv-logomarca-preview {
  width: 200px;
}

.hdv-fieldset {
  border: 1px solid silver;
  padding: 10px 10px 30px 10px;
  margin-top: 20px;
  background-color: #fbfbfb;
}

.hdv-legend {
  display: block;
  width: fit-content;
  max-width: 100%;
  padding: 0px 5px 0px 5px;
  margin-bottom: .5rem;
  font-size: 14px;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  background-color: #fff;
  font-weight: bold;
}

.hdv-automacao-order-button {
  background-color: #eee;
  padding: 4px 8px;
  border-radius: 4px;
  min-width: 80px;
  margin-right: 4px;
  border: 1px solid #9b9b9b;
}

.hdv-automacao-order-button:hover {
  filter: brightness(0.92);
}

.hdv-automacao-add-new-button {
  margin-top: 15px;
  padding: 8px 10px 7px 10px;
}

.hdv-automacao-delete-button {
  padding: 8px 10px 7px 10px;
}

.hdv-automacao-delete-button:hover {
  filter: brightness(0.9);
  cursor: pointer;
}

.hdv-icon-position-homebox {
  position: absolute;
  font-size: 90px;
  opacity: 0.1;
}

.hdv-icon-text-homebox {
  font-weight: bold;
}

.hdv-mapform {
  position: relative;
  height: 400px;
  margin-top: 8px;
}

.hdv-action-list {
  padding: 3px;
  background-color: #495057;
  border-radius: 4px 4px 4px 4px;
  color: white;
  padding: 4px 10px 3px 10px;
  cursor: pointer;
}

.hdv-menu-comandos {
  text-align: left;
  background: #7d7d7d;
  border-radius: 0px 4px 4px 4px;
  position: absolute;
  /* display: none; */
}

.hdv-popup-comando {
  list-style: none;
  padding: 0px;
  margin: 5px 5px 5px 0px;
}

.hdv-statustable {
  margin-left: 15px;
  width: 97%;
}

.hdv-statustable-header {
  background-color: #b1b1b1;
}

.hdv-statustable-header tr td {
  padding: 5px;
}

.hdv-statustable-header-firsttd {
  border: none;
  background-color: #fff;
}

.hdv-statustable-body tr td {
  background-color: #f1eded;
  border: 1px solid silver;
}

.hdv-statustable-tdtendencia-descendo {
  background-color: tomato !important;
}

.hdv-statustable-tdtendencia-subindo {
  background-color: #aeffce !important;
}

.hdv-statustable-innertable {
  width: 100%;
}

.hdv-statustable-innertable tr td {
  border: none;
  padding: 5px;
  width: 33%;
}

.hdv-statustable-innertable thead td {
  background-color: gainsboro;
}

.hdv-statustable-innertable thead td :first-child {
  border-right: 1px solid silver !important;
}

.hdv-mapform-status {
  position: relative;
  height: 180px;
  margin-top: 8px;
}

.hdv-table-offset {
  background-color: #fff !important;
  border: none !important;
}

.hdv-status-ligado {
  background-color: #10ac84;
  padding: 2px 20px 2px 20px;
  border-radius: 20px;
  color: #fff;
}

.hdv-status-desligado {
  background-color: #265792;
  padding: 2px 20px 2px 20px;
  border-radius: 20px;
  color: #fff;
}

.hdv-status-neutro {
  background-color: #7b6f71;
  padding: 2px 20px 2px 20px;
  border-radius: 20px;
  color: #fff;
}

.hdv-search-results {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0px 10px 0px;
  position: absolute;
  z-index: 10;
  width: 200px;
  border: 1px solid silver;
}

.hdv-search-results li {
  color: #000;
  margin-bottom: 5px;
}

/*CUSTOMIZACAO REACT TABS*/

.react-tabs__tab {
  font-size: 0.9rem;
}

.tabs-style {
  margin-top: 20px;
}

/*CUSTOMIZACAO REACT TABLE*/

.ReactTable .rt-table {
  font-size: 0.9rem;
}

.pagination-bottom {
  font-size: 0.9rem;
}

.ReactTable .-pagination {
  box-shadow: none !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.ReactTable .rt-thead.-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #ebeced;
  height: 48px;
  box-shadow: none !important;
}

span[id^="validacao-"] {
  color: rgb(207, 53, 53);
}

.tabela-eventos {
  overflow-y: auto;
  max-height: 320px;
  border: 1px solid #dadada;
}

.tabela-eventos td {
  padding: 3px 8px;
}

.btn-primary-sideccr {
  border: none;
  cursor: pointer;
  margin-top: 4px;
  padding: 6px 10px;
  border-radius: 2px;
}

.input-atraso {
  max-width: 140px !important;
  height: 30px !important;
  border: 1px solid #b6b6b6 !important;
}

i.fa span {
  display: none;
}

#font {
  color: #222 !important;
}

i.fa:hover span {
  display: block;
  position: absolute;
  width: 220px;
  padding: 15px 10px;
  border-radius: 3px;
  font-size: 14px;
  font-family: sans-serif;
  background-color: #fff;
  box-shadow: 0 0 9px #a1a1a1;
  animation: fade-in ease-in-out .26s;
}

.exportar-csv {
  background: #4da845;
  width: fit-content;
  height: 40px;
  margin: 15px 0;
  float: right;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.exportar-csv p {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 8px 10px;
}

.modal-header button.close {
  font-size: 1.6rem !important;
  padding: 10px 16px;
  outline: 0;
}

.hdv-resumo-relatorio {
  font-size: 18px;
  font-weight: bold;
  padding: 0 6px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.hdv-resumo-content {
  display: flex;
  flex-wrap: wrap;
}


.hdv-resumo-content div {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding: 32px 8px 8px 8px;
  border-radius: 10px;
  margin: 7px 10px 8px 0;
  font-size: 12px;
  width: 25%;
}

.hdv-resumo-content div strong {
  font-size: 20px;
}

.hdv-details .hdv-summary {
  padding: 8px 10px;
  background-color: #dfdfdf;
  border-radius: 3px;
  font-weight: bold;
  outline: 0;
  margin: 5px 0;
}

.hdv-details[open] .hdv-summary {
  color: white;
  background-color: #3393fa;
}

.hdv-sub-details summary::-webkit-details-marker {
  display: none;
}

.hdv-sub-details summary {
  font-weight: bold;
  margin-left: 15px;
  padding: 4px 10px;
  background-color: #eee;
  outline: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #555;
  justify-content: space-between;
  border-top: 1.1pt solid #cacaca;
}

.hdv-sub-details:last-child summary {
  border-bottom: 1.1pt solid #cacaca !important;
}

.hdv-sub-details summary:hover {
  filter: brightness(0.94);
}

.hdv-sub-details[open] summary {
  border-bottom: 1.1pt solid #cacaca;
  color: #111;
}

.hdv-sub-details summary .hdv-details-buttons button {
  padding: 3px 10px;
}

.hdv-sub-details .hdv-details-content {
  display: flex;
  margin-left: 15px;
  padding: 10px 0;
  color: #3b3b3b;
  padding-left: 20px;
  background-color: #eee;
}

.hdv-sub-details span {
  margin: 0 5px;
}

.hdv-sub-details span::after {
  content: '|';
  font-weight: bold;
  color: #999;
  margin-left: 10px;
}

.hdv-sub-details span:last-child::after {
  display: none;
}

table.hdv-table-analitico {
  width: 100%;
}

.hdv-table-analitico thead {
  background-color: #cecece;
}

.hdv-table-analitico thead th {
  padding: 12px 6px;
}

.hdv-table-analitico thead th:first-child {
  border-radius: 7px 0 0 7px;
}

.hdv-table-analitico thead th:last-child {
  border-radius: 0 7px 7px 0;
}

.hdv-table-analitico thead th {
  position: relative;
}

.hdv-table-analitico thead th::after {
  position: absolute;
  right: 0px;
  top: 10px;
  content: '';
  width: 2px;
  background-color: #aaaaaa;
  height: 25px;
  border-radius: 2px;
}

.hdv-table-analitico thead th:last-child::after {
  content: none;
}

.hdv-table-analitico tbody tr:nth-child(odd) {
  background-color: #eeeeee;
}

.hdv-table-analitico tbody td {
  padding: 0 5px !important;
}

.hdv-table-analitico tbody td:first-child {
  border-radius: 7px 0 0 7px !important;
}

.hdv-table-analitico tbody td:last-child {
  border-radius: 0 7px 7px 0 !important;
}

.hdv-table-analitico thead {
  font-size: 12px;
}

.hdv-table-analitico tbody td {
  font-size: 12px;
  position: relative;
}

.hdv-table-analitico tbody td::after {
  position: absolute;
  right: 0px;
  top: 10px;
  content: '';
  width: 2px;
  background-color: silver;
  height: 25px;
  border-radius: 2px;
}

.hdv-table-analitico tbody td:last-child::after {
  content: none;
}

.hdv-table-analitico .hdv-cell {
  padding: 0 0 !important;
  margin: 0 0 !important;
}

.hdv-table-analitico .hdv-cell p {
  margin: 0;
  padding: 2px 8px;
}

.hdv-table-analitico .hdv-center {
  padding: 13px 4px !important;
}

.hdv-badge {
  padding: 0px 7px;
  float: right;
  margin: 0 6px;
  border-radius: 50%;
}

.hdv-observacao-modal,
.modal-large {
  min-width: 70% !important;
}

.modal-md {
  min-width: 50% !important;
}

.modal-full {
  min-width: 80% !important;
}

.marker-filter:hover {
  cursor: pointer;
  filter: brightness(0.93);
}

.marker-filter.disabled {
  filter: brightness(0.72);
}

.aviso-backdrop {
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aviso-feedback {
  background-color: #f5f5f5;
  border-radius: 3px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.35);
  width: 500px;
  padding: 10px 12px;
}

.aviso-buttons {
  display: flex;
  justify-content: flex-end;
}

.aviso-buttons button {
  padding: 6px 20px
}

.campo-customizado {
  margin-top: 15px;
  max-height: 400px;
  overflow: auto;
  font-size: 13px;
}

.campo-customizado details {
  margin: 4px 0;
}

.campo-customizado details summary {
  border-radius: 4px;
  padding: 4px 8px;
  background-color: #f2f2f2;
}

.campo-customizado details div {
  padding: 0 6px;
  font-size: 14px;
  opacity: 0.94;
}

.campo-customizado p {
  margin: 4px 0 !important;
  border-radius: 4px;
  padding: 5px 8px;
  width: 100%;
  background-color: #eee;
}

.campo-customizado #variavel:hover {
  color: dodgerblue;
  cursor: pointer;
}

.hdv-param-number::-webkit-outer-spin-button,
.hdv-param-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hdv-param-buttons {
  margin-left: 6px;
  overflow: hidden;
}

.hdv-param-buttons button {
  background: #e4e4e4;
  padding: 1px 6px;
  border: none;
  font-size: 14px !important;
}

.hdv-param-buttons button:hover {
  filter: brightness(0.93);
}

.hdv-relatorio-details {
  border: 0.5mm solid #d9d9d9;
  border-radius: 4px;
}

.hdv-relatorio-details input[type="color"] {
  height: 30px;
  width: 60px;
  border-radius: 4px;
  border: none;
}

.hdv-relatorio-details label {
  display: flex;
  flex-direction: column;
  margin: 0;
}

table.hdv-relatorio-details button, .hdv-multi-report-dispositivos button {
  border: none !important;
  padding: 4px;
  margin: 0 3px;
  background-color: #ddd;
  border-radius: 50%;
}

.hdv-chart-close,
.hdv-chart-edit {
  border: none;
  border-radius: 4px;
  padding: 0;
  margin: 0 5px;
  height: 28px;
  width: 28px;
  color: #fff;
  cursor: pointer;
}

.hdv-chart-close:hover,
.hdv-chart-edit:hover {
  filter: brightness(0.92);
}

.hdv-chart-options {
  display: flex;
  align-items: center
}

.hdv-chart-options button {
  padding: 10px 10px !important;
  margin: 0;
  margin-left: 8px;
}

.hdv-relatorio-chart {
  overflow: hidden;
  margin: 7px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.32);
}

.hdv-relatorio-details summary::-webkit-details-marker,
.hdv-relatorio-chart summary::-webkit-details-marker {
  display: none;
}

.hdv-relatorio-chart details:hover {
  filter: brightness(0.8);
}

.hdv-relatorio-chart summary {
  background-color: #fff;
  list-style: none;
  padding: 6px 8px;
}

.hdv-relatorio-chart #content {
  padding: 6px 14px;
}

.hdv-relatorio-chart[open] {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.27);
}

.hdv-relatorio-chart[open] summary {
  border-bottom: 1px solid #dfdfdf;
}

.hdv-relatorio-chart summary:hover {
  filter: brightness(0.94);
}

.hdv-relatorio-details button {
  padding: 2px;
  border-radius: 50%;
  background-color: #777;
  justify-content: center;
  display: flex;
  align-items: center;
}

.hdv-relatorio-details summary button i {
  font-size: 20px !important;
}

#title-chart-config {
  font-size: 16px !important;
  width: 300px;
  padding: 4px 10px;
}

.hdv-resumo-list {
  display: flex;
  margin-top: 12px;
  padding: 2px 0;
  overflow-x: auto;
  position: relative;
  padding-top: 18px;
}

.hdv-resumo-list::before {
  position: absolute;
  top: -3px;
  opacity: 0.88;
  z-index: 1000;
  content: "Resumos:";
}

.hdv-resumo-list .hdv-resumo {
  height: 110px;
  width: 240px;
  word-break: break-all;
  min-width: 160px;
  margin: 4px 0;
  margin-right: 10px;
  background-color: #f6f6f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 13px;
  opacity: 0.8;
}

.hdv-resumo-list .hdv-resumo #content {
  display: flex;
  flex-direction: column;
}

.hdv-resumo-list .hdv-resumo #content strong {
  font-size: 24px;
}

.hdv-resumo-list .hdv-resumo i {
  font-size: 40px;
  margin: 0 6px;
}

.hdv-resumo-list .hdv-resumo i.hide-icon {
  font-size: 12px;
  opacity: 0;
}

.hdv-resumo-list .hdv-resumo small {
  font-size: 12px;
  display: block;
}

#hdv-content {
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  justify-content: stretch;
}

#hdv-content #ref-item section {
  display: none;
}

.sideccr-table td[hdv-title]:hover::before {
  content: attr(hdv-title);
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
  padding: 3px 8px;
  border-radius: 4px;
  left: 0px;
}

label.hdv-help {
  position: relative;
}

label.hdv-help::after {
  content: "?";
  height: 18px;
  width: 18px;
  font-size: 12px;
  text-align: center;
  border: 1px solid #555;
  border-radius: 10px;
  font-weight: bold;
  right: 0;
  position: absolute;
}

label.hdv-help span {
  display: none;
}

label.hdv-help:hover span {
  display: block;
  position: absolute;
  background-color: #fff;
  margin-top: 25px;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.rele-bullet{
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 15px;
    margin-left: 2px;
}

.rele-bullet-on{
  background-color: rgb(0, 224, 49);
}

.rele-bullet-off{
  background-color: red;
}

.rt-th > input{
  height: 42px !important;
}

.hdv-multi-report-item {
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hdv-multi-report-item p {
  margin: 0; 
}

.hdv-multi-report-item span {
  opacity: 0.85;
}

.hdv-multi-report-item button {
  border: none;
  border-radius: 50%;
  padding: 4px;
  margin: 0 2px;
}

#multi-item-list {
  padding: 0;
  border: 1px #d2d2d2 solid;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  height: 260px;
  overflow-y: scroll;
}

#multi-item-list label {
  padding: 2px 6px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

#multi-item-list label:hover {
  filter: brightness(0.92);
}

#multi-item-selected {
  height: 400px;
  overflow-y: auto;
  padding-bottom: 130px;
}

.hdv-multi-report-table progress {
  min-width: 85%;
  margin-right: 6px;
}

#hdv-multi-report-print {
  padding-bottom: 25px !important;
}

#multi-item-list button {
  border: none;
  padding: 8px;
  background-color: #dedede;
  margin: 5px;
  border-radius: 4px;
}

#multi-item-list button:hover {
  background-color: #0062cc;
  color: white;
}

#multi-item-list .multi-item-add {
  padding: 0 26px !important;
  color: #333;
  margin: 0 !important;
  user-select: none;
  font-size: 20px !important;
  font-weight: bold;
}

span.multi-item-selected {
  color: #0062cc;
  font-weight: bold;
}

.hdv-multi-report-dispositivos summary {
  font-size: 16px;
  font-weight: bold;
  border-radius: 2px;
  padding: 4px 0;
}

.hdv-multi-report-dispositivos summary:hover {
  background-color: #ebebeb;
}

.hdv-multi-report-dispositivos#perfil-usuario {
  margin-top: 8px;
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
}

.hdv-multi-report-dispositivos#perfil-usuario section {
  max-height: 480px;
  min-height: 150px;
  overflow-y: auto;
}

.warning-animation {
  animation-timing-function: ease-out;
  animation-duration: 1s;
  animation-delay: .5s;
  animation-name: warning_animation;
  animation-iteration-count: infinite;
}

@keyframes warning_animation {
  from {box-shadow: none;}
  to {box-shadow: 0 0 0 2px #0062cc;}
}