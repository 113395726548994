body{
    background-color: #fdfdfd !important;
}

.hdv-body-app{
    /*margin-top: 70px !important;*/
}

.marker-filter{
    width: 25%;
    position: relative;
}

.hdv-zera-padding-left-right-boostrap{
    margin-top: 0px !important;
    border-radius: 10px !important;
}

.fluid {
    margin-left: 250px;
    height: 100%;
    position: relative;
    top: -30px;
}

.hdv-app-container{
    height: -webkit-fill-available;
}

.hdv-body-app {
    width: 100%;
    margin-top: 0px !important;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: 0px;
    height: 100%;
}

.hdv-render-div{
    top: -65px;
    position: relative;
    border-radius: 10px !important;
}

.home-new-style{
    padding: 0px !important;
    border-radius: 10px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    height: 100%;
    top: 0px;
    position: relative;
}

.hdv-bloco-top-space{
    height: 100%
}

.hdv-conteudo {
    height: 100%;
}

.hdv-conteudo > .row {
    padding-top: 160px;
    height: 100%;
    margin-right: -20px;
    margin-left: -20px;
}

.map-marker-toggle {
    position: relative;
    top: -50px;
    z-index: 9;
    height: 112px;
}

.map-marker-toggle button {
    border: none;
    list-style: none;
    padding: 6px 7px;
    box-shadow: 0 0 3px rgba(0,0,0,0.26);
    background-color: white;
    border-radius: 1px;
    position: relative;
    color: #424242;
    z-index: 20;
    left: 8px;
    bottom: -16px;
}

.marker-toggle-list {
    background-color: #fff !important;
    border-radius: 2px;
    box-shadow: 0 0 6px rgba(0,0,0,0.3);
    position: absolute;
    left: 8px;
    bottom: -388px;
    padding: 0 8px;
    width: 290px;
    height: 375px;
}

.marker-toggle-list input {
    margin-top: 6px;
    padding: 5px 8px;
    width: 230px;
    margin-left: 40px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.marker-toggle-list ul {
    margin-top: 10px;
    overflow: auto;
    height: 86%;
    padding: 0;
}

.map-marker-toggle li {
    list-style: none;
    padding: 4px 3px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    user-select: none;
}

.marker-toggle-list li .badge {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 4px;
}

.map-marker-toggle li:hover{
    cursor: pointer;
    background-color: #eee;
    color: #3273d4;
}

.marker-filter {
    display: inline-block;
    padding: 0px 10px;
    position: relative;
}

.hdv-bloco-informacao {
    background-color: transparent !important;
}

.hdv-bloco-informacao, .hdv-info-bloco{
    border-radius: 10px !important;
}

.hdv-info-bloco-off{
    background: #606c88; /* Old browsers */
    background: -moz-linear-gradient(top,  #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #4b4b4b 0%,#3d3d3d 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#606c88', endColorstr='#3f4c6b',GradientType=0 ); /* IE6-9 */
}

.hdv-info-bloco-sem-comunicacao{
    background: #606c88; /* Old browsers */
    background: -moz-linear-gradient(top,  #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ff5252 0%,#b33939 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#606c88', endColorstr='#3f4c6b',GradientType=0 ); /* IE6-9 */
}

.hdv-info-bloco-alert{
    background: #606c88; /* Old browsers */
    background: -moz-linear-gradient(top,  #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ffb142 0%,#cc8e35 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#606c88', endColorstr='#3f4c6b',GradientType=0 ); /* IE6-9 */
}

.hdv-info-bloco-active{
    background: #606c88; /* Old browsers */
    background: -moz-linear-gradient(top,  #606c88 0%, #3f4c6b 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #606c88 0%,#3f4c6b 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #33d9b2 0%,#218c74 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#606c88', endColorstr='#3f4c6b',GradientType=0 ); /* IE6-9 */
}

#map{
    border-radius: 0px;
}

.top-map-degrade{
    background: white;
    /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e7e7e7', endColorstr='#00ffffff',GradientType=0 );  */
    height: 10px;
    position: absolute;
    width: 100%;
    box-shadow: 14px -12px 60px 10px rgba(255, 255, 255, 1), 14px -6px 12px 7px rgba(255, 255, 255, 1),  14px 8px 8px 12px rgba(255, 255, 255, 0.8);
    z-index: 9;
    top: 0px;
}

.hdv-top-menu-position{
    position: relative;
    top: 15px;
    z-index: 10;
}

.hdv-search-home-field{
    border-radius: 10px !important;
}

.hdv-search-home-field::placeholder{
    color: #fff !important;
    font-size: 10px;
    font-style: italic;
}

.ReactTable .rt-thead.-header{
    /*background-color: #4d4d4d !important;
    border: 1px solid #4d4d4d !important;*/
    height: 48px !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /*color: #fff;*/
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{
    padding: 13px 5px 5px 5px !important;
}

.hdv-zera-padding-left-right-boostrap > h4{
    margin-top: 20px;
}

.hdv-zera-padding-left-right-boostrap > div > h4{
    margin-top: 20px;
}

.ReactTable{
    border-radius: 5px;
}

.hdv-btn-forms{
    padding: 10px 35px 10px 35px !important;
    border-radius: 5px !important;
}

.hdv-btn-group{
    margin-bottom: 22px !important;
}

.react-tabs__tab{
    background-color: #dedede;
    border-radius: 5px 5px 0px 0px;
    margin-left: 2px;
    border-bottom: 1px solid #aaaaaa !important;
    padding: 10px 16px !important;
}

.react-tabs__tab--selected{
    background: #4d4d4d !important;
    border-color: #aaa !important;
    color: white !important;
    border-radius: 5px 5px 0 0 !important;
}

.hdv-form-default > span{
    width: 100%;
    display: block;
    padding: 0px 10px;
    margin-top: 2px;
    border-radius: 5px;
}

.hdv-form-default > span[id^="validacao-"]{
    width: 100%;
    background-color: tomato !important;
    color: #fff;
}

.css-bg1rzq-control{
    height: 42px;
}

.css-1szy77t-control{
    height: 42px;
}

.css-bg1rzq-control > div:first-child{
    height: 42px;
}

.css-1szy77t-control > div:first-child{
    height: 42px;
}

.css-1g6gooi{
    margin: -2px !important
}

.hdv-bloco-informacao{
    background-color: #fbfbfb !important;
}