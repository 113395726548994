html, body, #root, .hdv-maxheight{
    height: 100%;
}

.body-page{
    height: 100%;
    background: #61abe8;
    background: -moz-linear-gradient(top, #61abe8 0%, #5fa7e3 56%, #2f577b 100%);
    background: -webkit-linear-gradient(top, #61abe8 0%,#5fa7e3 56%,#2f577b 100%);
    background: linear-gradient(to bottom, #61abe8 0%,#5fa7e3 56%,#2f577b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61abe8', endColorstr='#2f577b',GradientType=0 );
}

.center-login{
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    background-color: #37424A;
    width: 1050px;
    height: 520px;
    margin-left: -526px;
    margin-top: -300px;
    border-radius: 5px;
}

.form-login{
    background-color: #fff;
    border-radius: 4px;
    width: 550px;
    position: absolute;
    right: 40px;
    height: 550px;
    top: -15px;
}

.labels{
    display: block;
    margin-top: 30px;
    color: #575757;
    font-weight: 700;
    text-align: left;
    margin-left: 28px;
    font-size: 11px;
    margin-bottom: 5px;
}

.form-login input[type="text"], input[type="password"] {
    width: 90%;
    padding: 30px 0px 29px 30px;
    background-color: #F4F4F4;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    color: #575757;
    height: 40px;
}

.titulo{
    font-size: 25px;
    font-weight: 700;
    margin-top: 55px;
    color: #575757;
}

.btn-login{
    background-color: #58A9EC;
    width: 207px;
    margin-top: 26px;
    color: white;
    padding: 21px;
    border-radius: 4px;
    position: relative;
    right: -315px;
    margin-bottom: 50px;
    font-weight: 700;
}

.btn-login:hover{
    background-color: #487aa2;
    cursor: pointer;
}

.alogin{
    color: #58A9EC !important;
    text-decoration: none;
    font-size: 12px;
}

a:hover{
    text-decoration: none;
}

.permanecer-conectado{
    float: left;
    position: absolute;
    bottom: 162px;
    left: 90px;
}

.permancer-label{
    color: #575757;
    font-weight: 700;
    font-size: 12px;
    margin-left: 10px;
}

.gota{
    position: absolute;
    left: -100px;
    top: -20px;
    opacity: 0.2;
}

.logomarca{
    text-align: center;
    z-index: 10;
    position: absolute;
    left: 100px;
    top: 100px;
}

.subtitulo-logo{
    color: #aaa;
    font-weight: 300;
}

.texto-rodape{
    position: absolute;
    text-align: right;
    bottom: 35px;
    font-size: 12px;
    color: #aaa;
    right: 596px;
}

.sociais{
    position: absolute;
    bottom: 4px;
    left: 346px;
}

.fa-white {
    width: 25px;
    height: 25px;
    font-size: 24px;
    border-radius: 2px;
    color: #fff;
    margin-left: 10px;
}

.facebook-social{
    background: #1c68a7;
}

.lkdn-social{
    background: #3b93dc;
}

.tube-social{
    background: red;
}

.mensagem-erro{
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    background-color: #ce4444;
    width: 354px;
    height: 125px;
    margin-left: -195px;
    margin-top: -400px;
    border-radius: 5px;
    color: white;
    padding: 17px;
    z-index: 10;
}

.mensagem-icone{
    font-size: 48px;
    width: 48px;
}

input[type="checkbox"]{
    appearance:none;
    width:40px;
    height:16px;
    border:1px solid #aaa;
    border-radius:2px;
    background:#ebebeb;
    position:relative;
    display:inline-block;
    overflow:hidden;
    vertical-align:middle;
    transition: background 0.3s;
    box-sizing:border-box;
    outline: none;
  }
  input[type="checkbox"]:after{
    content:'';
    position:absolute;
    top:0px;
    left:-1px;
    width:14px;
    height:14px;
    background:white;
    border:1px solid #aaa;
    border-radius:2px;
    transition: left 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  input[type="checkbox"]:checked{
    background:#58A9EC;
    border-color:#8daee5;
  }
  input[type="checkbox"]:checked:after{
    left:23px;
    border-color:#8daee5;
  }

  input[type="checkbox"]:hover:not(:checked):not(:disabled):after,
  input[type="checkbox"]:focus:not(:checked):not(:disabled):after{
    left:0px;
  }

  input[type="checkbox"]:hover:checked:not(:disabled):after,
  input[type="checkbox"]:focus:checked:not(:disabled):after{
    left:22px;
  }

  input[type="checkbox"]:disabled{
    opacity:0.5;
  }

